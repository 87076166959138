import React, { useState, useEffect, useRef } from 'react'; 
import Navbar from "../Navbar/Navbar"
import "./NFT.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import NFTImage from "./../../images/guy.png"

AOS.init();


function NFT() {
  const [NFTIncrement, SetNFTIncrement] = useState(0)
  
  function incrementCounter(){
    if(NFTIncrement < 3){
      SetNFTIncrement(NFTIncrement + 1)
    }
  }

  function decrementCounter(){
    if(NFTIncrement > 0){
      SetNFTIncrement(NFTIncrement - 1)
    }
  }


  return (
    <div className="home-body">
        <Navbar />
        <div className='staking-body'>
        <h1>Coming Soon...</h1>
      </div>
    </div>
  );
}

export default NFT;