import './App.css';
//import './components/pages/Navbar/Navbar.css'
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import AnimatedRoutes from './components/utils/pageAnimation/AnimatedRoutes';
import background from "./components/images/background.jpg"

function App() {

  const myStyle = {
    backgroundImage: `url(${background})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const myStyleOverlay = {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <Router>
      {/* <Navbar /> */}
      <div style={myStyle}>
        <div style={myStyleOverlay}>
          <AnimatedRoutes/>        
        </div>
      </div>

      {/* <Footer/> */}
    </Router>
  );
}

export default App;
