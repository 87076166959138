export const MenuList = [
    {
      title: "Home",
      url: "/",
    },
    // {
    //   title: "Token Presale",
    //   url: "/presale",
    // },
    {
      title: "NFT",
      url: "/NFT",
    },
    {
      title: "Staking",
      url: "/staking",
    }
    // },
    // {
    //   title: "FAQ",
    //   url: "/FAQ",
    // }
  ];
  