import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { MenuList } from "./MenuList";
import "./Navbar.css";
import discord from "./../../images/discord.png"
import twitter from "./../../images/twitter.png"
//import '@fortawesome/fontawesome-free/css/all.min.css';

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const [style, setStyle] = useState("")

  const menuList = MenuList.map(({ url, title }, index) => {

    return (
      <li key={index}>
        <NavLink exact to={url} activeClassName="active"> 
        {/* onClick={changeStyle}> */}
          {title}
        </NavLink>
      </li>
    );
  });

  function openTelegram(){
    window.open(
      'https://t.me/hanslowlana',
      '_blank'
    );
  }
  function openTwitter(){
    window.open(
      ' https://twitter.com/hanslowlanacoin',
      '_blank'
    );
  }

  var bars =  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path fill="white" d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>

  const handleClick = () => {
    setClicked(!clicked);

    bars = <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 352 512"><path fill="white" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>

  };

  // const times = <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 352 512"><path fill="white" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>

  return (
    <nav className="nav"> 
      <div className="mobile-nav-container">            
        <div className="logo">
          <a className="han">Han</a><a className="slowlana">Slowlana</a>
        </div>
        <div className="menu-icon" onClick={handleClick}>
          <i className="bars">{bars}</i>
        </div>
        
        <ul>
          <ul className={clicked ? "menu-list" : "menu-list close"}>
            {menuList} 
            <div className="socials-nav" onClick={openTwitter}>Twitter</div>
            <div className="socials-nav" onClick={openTelegram}>Telegram</div>
            {/* <button className="socials-button" onClick={openDiscord}><img src={discord}></img></button>
            <button className="socials-button" onClick={openTwitter}><img src={twitter}></img></button> */}
          </ul>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
