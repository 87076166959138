import React, { useState, useEffect, useRef } from 'react'; 
import Navbar from "../Navbar/Navbar"
import "./home.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import NFTImage from "./../../images/hanslowlana.png"
import NFTImage2 from "./../../images/hanslowlana2.png"

AOS.init();


function Home() {

  function presaleRedirect(){
    window.open(
      'https://birdeye.so/token/3dSfczd9NM75tyjRtbZVfVynyTS1gs9RNLQemJFXB8DH?chain=solana',
      '_blank'
    );
    
  }

  return (
    <div>
      <div className="home-body">
          <Navbar />
          <div className='home-page-body'>
              <div className='home-inner-body'>
                <div className='home-image'>
                  <img src={NFTImage}></img>
                </div>
              </div>
              <div className='hero-title-body'>
                  <div className='hero-title'>
                    <div className='hero-title-han'>Han</div>
                    <div className='hero-title-slowlana'>Slowlana</div>
                    <div className='hero-title-desription'>
                      <p>From the creators of Star Wars  comes Han Slowlana. <br></br>Yes we’re 97 year old devs, get fucked.</p>
                    </div>
                    <div className='hero-title-button-body'>
                      <button className='hero-title-button' onClick={presaleRedirect}>BUY $HAN</button>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div className='home-body-bottom'>
        <div className='home-page-body'>
            <div className='explantion-body'>
                <div className='explantion-text'>
                  <h1>Who is Han <a>Slowlana</a></h1>
                  <p>
                  Hi lads! Han Slowlana here.  
                  <br></br><br></br>
                  I know that the network has been absolute shit, so im here (with my trusty light saber) to save you all from the constant congestions and mess that we deal with daily. 
                  Its been really tough trading lately with all the jeets and rugs so what I am doing to combat that is stabbing people with my light saber, directly in their anus. 
                  <br></br><br></br>
                  Example, you jeet, I pullout the blicky and burn your ass. All bullshit aside, here at $HAN we offer an upgraded user experience to our holders by implementing staking, wagering and crypto options into our eco system. 
                  Very simple...</p>
                </div>
            </div>
            <div className='home-inner-body'>
              <div className='home-image'>
                <img src={NFTImage2}></img>
              </div>
            </div>
        </div>
        <div className='bottom-home-page-body'>
            <div className='explantion-body'>
              <div className='how-it-works-section2'>
                <div className='title-how-it-works'>
                    <h1>HOW IT <a>WORKS</a></h1>
                </div>
                <div className='how-it-works-section-text-holder'>
                    <div>
                        <h1>STEP 1</h1>                        
                    </div>
                    <div>
                        <p>Stake your $HAN...</p>
                    </div>
                </div>
                <div className='how-it-works-section-text-holder'>
                    <div>
                        <h1>STEP 2</h1>                        
                    </div>
                    <div>
                        <p>Place your wager...</p>
                    </div>
                </div>
                <div className='how-it-works-section-text-holder'>
                    <div>
                        <h1>STEP 3</h1>                        
                    </div>
                    <div>
                       <p>Winning wagers are paid out in $HAN / Losing wagers go back into the vault for the next days rounds of wagers. 
                        If this is too difficult for you to comprehend, youre clearly fuckin retarded, possibly gay, or both and we dont want you here. 
                          <br></br><br></br>Upon completion of presale,  999 NFT's  will be airdropped to all presale buyers for token gated access to the discord, 
                          this is where the magic happens...</p>
                    </div>
                </div>
                {/* <div className='how-it-works-section-text-holder2'>
                    <div>
                        <h1>What does the NFT offer</h1>                        
                    </div>
                    <div>
                       <p>#1 Sports picks from one of vegas top Handicappers will be posted daily (over $1000 daily value of sports picks).</p>
                       <p>#2 Where we will vote daily on which events to add to our gambling UI for daily wagering of $Han.</p>
                    </div>
                </div> */}
              </div>
            </div>
        </div>
        <div className='bottom-home-page-body'>
            <div className='explantion-body'>
              <div className='how-it-works-section2'>
                <div className='title-how-it-works'>
                    <h1>What does the <a>NFT</a> Offer</h1>
                </div>
                <div className='how-it-works-section-text-holder'>
                    <div>
                        <h1>#1</h1>                        
                    </div>
                    <div>
                       <p>Sports picks from one of vegas top handicappers will be posted daily <br></br> (over $1000 daily value of sports picks).</p>
                    </div>
                </div>
                <div className='how-it-works-section-text-holder'>
                    <div>
                        <h1>#2</h1>                        
                    </div>
                    <div>
                       <p>Where we will vote daily on which events to add to our gambling UI for daily wagering of $Han.</p>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Home;