import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Home from "../../pages/home/home";
import Presale from "../../pages/presale/presale";
import NFT from "../../pages/NFT/NFT";
import Staking from '../../pages/staking/staking';
import FAQ from "../../pages/FAQ/FAQ";

//import { AnimatePresence } from 'framer-motion/dist/framer-motion';

function AnimatedRoutes() {
    const location = useLocation();

    return(
        //<AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
                <Route path="/" exact component={Home} />
                {/* <Route path="/presale" exact component={Presale}/> */}
                <Route path="/NFT" exact component={NFT}/>
                <Route path="/staking" exact component={Staking}/>
                <Route path="/FAQ" exact component={FAQ}/>
            </Switch>
        //</AnimatePresence>
    );
}

export default AnimatedRoutes