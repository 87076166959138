import React, { useState, useEffect, useRef } from 'react'; 
import Navbar from "../Navbar/Navbar"
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();


function Presale() {

  return (
    <div className="home-body">
      <Navbar/>
    </div>
  );
}

export default Presale;