import React, { useState, useEffect, useRef } from 'react'; 
import Navbar from "../Navbar/Navbar"
import "./staking.css"
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();


function Staking() {

  return (
    <div className="home-body">
      <Navbar/>
      <div className='staking-body'>
        <h1>Coming Soon...</h1>
      </div>
    </div>
  );
}

export default Staking;